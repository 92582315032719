import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["closeButton", "popover", "toggleButton",]

  toggle() {
    this.popoverTarget.toggleAttribute("hidden")
  }

  close(event) {
    if (event.target === this.toggleButtonTarget || this.popoverTarget.hasAttribute("hidden")) {
      return false
    }
    if ((this.closeButtonTargets.includes(event.target)) || (!this.popoverTarget.contains(event.target))) {
      this.popoverTarget.setAttribute("hidden", "")
    }
  }
}
