import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["apiKey", "requestForm", "serverUrl"]

  static values = { operationPath: String, documentationId: String }


  connect() {
    this.sessionStorageName = `documentation:${this.documentationIdValue}:api-key`
    this.#setApiKeys()
  }

  changeServer(event) {
    const server = event.currentTarget.dataset.serverUrl
    this.serverUrlTarget.textContent = server
    this.requestFormTarget.setAttribute("action", `${server}${this.operationPathValue}`)
    this.#setApiKeys()
  }

  // let users get entered tokens back when switching servers
  // TODO: add js helper debounce
  updateApiKey(event) {
    const apiKeys = this.#retrieveApiKeys()
    apiKeys[this.serverUrlTarget.textContent] = event.currentTarget.value
    sessionStorage.setItem(this.sessionStorageName, JSON.stringify(apiKeys))
  }

  #setApiKeys() {
    const server = this.serverUrlTarget.textContent
    const apiKeys = this.#retrieveApiKeys()
    this.apiKeyTarget.value = apiKeys && apiKeys[server] ? apiKeys[server] : ""
  }

  #retrieveApiKeys() {
    return JSON.parse(sessionStorage.getItem(this.sessionStorageName)) || {}
  }
}
