import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["input", "results", "result", "content", "filler", "caret", "form", "listElement", "loader", "searchIcon"]

  static values = {
    docUrl: String
  }

  initialize() {
    // Simple enough unique identifier for same search session
    this.identifierValue = Math.random().toString(16)
    this.tabindex = -1
  }

  connect() {
    this._changeShortcut()
    this.formTarget.querySelector("input#uid").value = this.identifierValue
  }

  submitOnType() {
    clearTimeout(this.timeout)
    if (this.inputTarget.value.length > 0) {
      this.#toggleLoader(true)
      this.timeout = setTimeout(() => {
        Rails.fire(this.formTarget, "submit")
      }, 500)
    } else {
      this.clearResults()
      this.resultsTarget.replaceChildren(document.importNode(this.fillerTarget.content, true))
    }
  }

  #toggleLoader(toggle) {
    this.loaderTarget.setAttribute("aria-hidden", !toggle)
    this.loaderTarget.setAttribute("aria-busy", toggle)
    this.searchIconTarget.setAttribute("aria-hidden", toggle)
  }

  _changeShortcut() {
    if (navigator.userAgent.indexOf("Mac")!=-1) {
      const commandLabel = this.element.querySelector(".command")
      commandLabel.textContent = commandLabel.getAttribute("data-osx-key")
    }
  }

  onFocus() {
    this.inputTarget.value = ""
    this.inputTarget.focus()
    this.resultsTarget.replaceChildren(document.importNode(this.fillerTarget.content, true))
  }

  clearResults() {
    this.#toggleLoader(false)
    this.resultsTarget.replaceChildren()
  }

  onBeforeSend(event) {
    if (this.currentSearchRequest) {
      const [xhr] = this.currentSearchRequest.detail
      if (xhr) {
        xhr.abort()
      }
    }
    this.currentSearchRequest = event
  }

  onPostSuccess(event) {
    let [data,,] = event.detail
    const self = this
    this.clearResults()

    if (Object.values(data).every(element => !element.length)) {
      self.resultsTarget.innerText = `No results found for "${self.inputTarget.value}"`
    }
    Object.entries(data).forEach(([groupName, nodes]) => {
      if (nodes.length === 0)
        return

      let groupNode = document.createElement("h3")
      groupNode.appendChild(document.createTextNode(groupName))
      self.resultsTarget.appendChild(groupNode)

      const list = document.createElement("ul")
      self.resultsTarget.appendChild(list)

      nodes.forEach(data => {
        const resultNode = document.importNode(self.resultTarget.content, true)

        if (groupName === "apis") {
          const apiNode = document.querySelector("#api-" + data.api.id)

          if (apiNode) {
            apiNode.setAttribute("data-doc-search-target", "listElement")
            resultNode.querySelector("li").replaceChildren(
              document.importNode(document.querySelector("#api-" + data.api.id), true)
            )
          } else {
            self._renderApiNode(resultNode, data)
          }
        } else {
          self._renderNode(resultNode, data)
        }

        list.appendChild(resultNode)
      })
    })

    this.tabindex = -1
  }

  _renderApiNode(resultNode, data) {
    const self = this
    let {description} = data.properties

    const label = resultNode.querySelector(".result-api-name")
    const resultTitle = resultNode.querySelector(".result-title")
    const resultLink = resultNode.querySelector(".list-element")
    const resultDetails = resultNode.querySelector(".result-details")

    resultLink.href = data.api.url

    self._populateResultTitle({properties: data.api}, resultTitle)
    label.remove()

    if (description) {
      resultDetails.innerHTML = description
    } else {
      resultDetails.remove()
    }
  }

  _renderNode(resultNode, data) {
    const self = this
    let {id, description, subtitle, breadcrumb} = data.properties
    let apiUrl = self.docUrlValue

    const label = resultNode.querySelector(".result-api-name")
    const resultLink = resultNode.querySelector(".list-element")
    const resultDetails = resultNode.querySelector(".result-details")

    if (data.api) {
      let {url, name: apiName} = data.api
      apiUrl = url

      label.innerText = apiName
    } else {
      label.remove()
    }

    resultLink.href = apiUrl.replace(/\/$/, "") + self._getPath(breadcrumb, id)
    resultLink.setAttribute("data-section-id", id)

    // TODO: return an operation id not based on breadcrumbs
    if (breadcrumb.length > 1) {
      resultLink.setAttribute("data-operation-id", breadcrumb[1].id)
    }
    self._populateResultTitle(data, resultNode.querySelector(".result-title"))

    let descriptionContent = []
    if (subtitle) {
      descriptionContent.push(subtitle)
    }
    if (description) {
      descriptionContent.push(description)
    }
    if (descriptionContent.length) {
      resultDetails.innerHTML = descriptionContent.join(" - ")
    } else {
      resultDetails.remove()
    }
  }

  _getPath(breadcrumb, id) {
    // this is a crutch to be able to build url from search results but it would be better
    // to get this information from another attribute
    if (breadcrumb.length === 0) {
      return `/group/${id}`
    } else if (breadcrumb.length === 1) {
      return `/operation/${id}`
    }
    // the second element of a breadcrumb is always the parent operation so
    // we can use its id to build the url
    return `/operation/${breadcrumb[1].id}#${id}`
  }

  _populateResultTitle(node, title) {
    let {display_name: name, name: rawName, breadcrumb} = node.properties
    const displayName = document.createElement("strong")
    displayName.innerHTML = name || rawName

    if (breadcrumb) {
      const icon = document.importNode(this.caretTarget.content, true)
      breadcrumb.forEach((element) => {
        // element : String | {id: String, name: String}
        //
        // Breadcrumb elements used to only be a name (String). But
        // they can now be an object with an id attribute (String) and
        // a name attribute (String)
        const {name} = element
        title.innerHTML += name || element
        title.appendChild(icon.cloneNode(true))
      })
    }
    title.appendChild(displayName)

    return title
  }

  onPostError(event) {
    let [data, , xhr] = event.detail
    let result = "<p>Search could not be processed, please contact Bump.sh support if it persists.</p>"

    if (xhr.status === 401) {
      result = "<p>Please refresh the page to reauthenticate and proceed with your search again.</p>"
    }

    this.#toggleLoader(false)
    this.resultsTarget.innerHTML = result
  }

  previousElement(event) {
    event.preventDefault()
    const results = this.listElementTargets
    if (this.tabindex - 1 < 0) {
      this.inputTarget.focus()
      this.tabindex = -1
    } else {
      const focus = results[this.tabindex -= 1]
      this.#setFocus(focus)
    }
  }

  nextElement(event) {
    event.preventDefault()
    const results = this.listElementTargets
    if (this.tabindex + 1 < results.length) {
      const focus = results[this.tabindex += 1]
      this.#setFocus(focus)
    }
  }

  #setFocus(element) {
    element.focus()
    element.scrollIntoView({ block: "center", behavior: "instant" })
  }

  clickResult(event) {
    event.preventDefault()

    const id = event.currentTarget.getAttribute("data-section-id")
    const operationId = event.currentTarget.getAttribute("data-operation-id")
    const operation = operationId && document.getElementById(operationId)

    if (operation && operation.tagName === "TURBO-FRAME" && operation.getAttribute("complete") === null) {
      this.dispatch("navigateToFrame", { target: document, prefix: false, detail: { section: operation, attributeId: id, highlight: true } })
      operation.removeAttribute("loading")
      operation.reload()
    } else {
      const activeItem = document.getElementById(id)
      if (activeItem) {
        activeItem.scrollIntoView()
        this.dispatch("highlight", { target: document, detail: { element: activeItem, scroll: true }, prefix: false })
      } else {
        operation && operation.scrollIntoView()
      }
    }
  }
}
